require("@rails/ujs").start()
require("@rails/activestorage").start()

global.$ = require('jquery')

import 'bootstrap'

// Local assets

require('../shared/csrf_token')
require('../shared/banana')
require('../shared/flash')
require('../pages/home')
