gravity  = 9.80665
friction = 200
bananas  = []

randomIntFromInterval = (min, max) -> # min and max included
  Math.floor(Math.random() * (max - min + 1) + min)

generateBanana = ->
  direction = if Math.random() < 0.5 then 'left' else 'right'

  banana =
    id: Math.floor(Math.random() * 1000000001);
    position:
      x: if direction == 'right' then -50 else $(window).width() - 50
      y: $(document).scrollTop() + ($(window).height()/2.0) + randomIntFromInterval(-$(window).height()/4, $(window).height()/4) # 50% of center of active screen
    velocity:
      x: if direction == 'right' then randomIntFromInterval(2, 20) else randomIntFromInterval(-2, -20)  # lateral speed
      y: randomIntFromInterval(-20, 3) # vertical speed (negative for top direction)
    angle: randomIntFromInterval(-1000, 1000)
    angularVelocity: randomIntFromInterval(-6, 6)
    weight: 0.118 # One medium banana (~7” long) weighs 118 grams.

  bananaDiv = document.createElement('div');
  bananaDiv.id = "banana-#{banana.id}"
  bananaDiv.className = 'banana'
  bananaDiv.innerHTML = '&nbsp;';
  document.body.appendChild(bananaDiv)

  return banana

bananaXplosion = ->
  for i in [1..6]
    setTimeout(() =>
      bananas.push(generateBanana())
    , i*400)

bananaKeepCondition = (banana) ->
  banana.position.y < $('body')[0].getBoundingClientRect().height - 50

bananaRemovelCondition = (banana) ->
  !bananaKeepCondition(banana)

bananaLoop = (timeStamp) ->
  for banana in bananas
    # Adapt velocity (vertical gravity and horizontal air friction)
    banana.velocity.y = banana.velocity.y + banana.weight * gravity * (1.0/60.0) * 10 # Gravity: 10 for pixel\m scale? It works quite well
    banana.velocity.x = banana.velocity.x - banana.velocity.x / friction              # Air friction

    # Adapt angular velocity (also called Bananacceleration)
    banana.angularVelocity = banana.angularVelocity + 2 * (1.0/60.0)

    # Adapt position
    banana.position.x = banana.position.x + banana.velocity.x
    banana.position.y = banana.position.y + banana.velocity.y

    # Adapt angle
    banana.angle = banana.angle + banana.angularVelocity

    div = document.getElementById("banana-#{banana.id}")

    div.style.left      = "#{banana.position.x}px"
    div.style.top       = "#{banana.position.y}px"
    div.style.transform = "rotate(#{banana.angle}deg)";

  # Remove div of banana to removes
  bananas.filter(bananaRemovelCondition).forEach((banana) ->
    document.getElementById("banana-#{banana.id}").remove()
  )

  # Remove deleted bananas from array
  bananas = bananas.filter(bananaKeepCondition)

  window.requestAnimationFrame(bananaLoop)

$ ->
  if $('.banana-trigger').length
    window.requestAnimationFrame(bananaLoop)

    # Trigger of banana launch
    $('.banana-trigger').on('click', ->
      bananas.push(generateBanana())
    )

    # Big BananaXplosion after 15 seconds
    setTimeout(bananaXplosion, 15000)

    # Then one time after 5 minutes
    setTimeout(bananaXplosion, 5*60*1000)
