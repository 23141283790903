$ ->
  # tooltip in plans
  $('[data-toggle="tooltip"]').tooltip()

  # Call-to-action "je suis un..."
  $('#orang-outan').on('click', => fillForm('Orang Outan'))
  $('#chimpanze'  ).on('click', => fillForm('Chimpanzé'))
  $('#ouistiti'   ).on('click', => fillForm('Ouistiti'))

  bindBlur = ->
    $('.container-photos').on('mouseenter', 'img', ->
      $('.container-photos img, .container-photos video').addClass('blur')
      $(this).removeClass('blur')
    )

    $('.container-photos').on('mouseenter', 'video', ->
      $('.container-photos img, .container-photos video').addClass('blur')
      $(this).removeClass('blur')
    )

    $('.container-photos').on('mouseleave', 'img', ->
      $('.container-photos img, .container-photos video').removeClass('blur')
    )

    $('.container-photos').on('mouseleave', 'video', ->
      $('.container-photos img, .container-photos video').removeClass('blur')
    )

  # Disabled because a bit too much
  # bindBlur()

  fillForm = (planName) ->
    $('textarea[name="message"').val("Bonjour les Monkeys,\n\nJe suis intéressé par votre plan \"#{planName}\" et j'aimerais prévoir une visite des bureaux.\n\nPourriez-vous me communiquer vos disponibilités ?\n\nMerci d'avance,")
    $('textarea[name="message"').addClass('flash')
    setTimeout((=> $('textarea[name="message"').removeClass('flash')), 6000)

    document.getElementById('contact-form').scrollIntoView()

  # Send contact form
  $('#contact-form input[type="submit"]').on('click', (event) ->
    event.preventDefault()

    if $('#contact-form form')[0].reportValidity()
      $('.alert-loading').show()
      $('.row-contact-form').hide()
      $('.alert-success').hide()

      params =
        name:    $('input[name="name"]').val()
        email:   $('input[name="email"]').val()
        phone:   $('input[name="phone"]').val()
        message: $('textarea[name="message"]').val()

      $.post('emails.json', params, (response) =>
        $('.alert-loading').hide()
        $('.row-contact-form').show()

        if response.success
          $('.alert-success').show()

          $('input[name="name"]').val('')
          $('input[name="email"]').val('')
          $('input[name="phone"]').val('')
          $('textarea[name="message"]').val('')
      )
  )
